module.exports = {
  email: "usmonhakimov1999@gmail.coom",
  navLinks: [
    {
      name: "About",
      url: "/#about",
    },
    {
      name: "Experience",
      url: "/#jobs",
    },
    {
      name: "Work",
      url: "/#projects",
    },
    {
      name: "Contact",
      url: "/#contact",
    },
  ],
  socialMedia: [
    {
      name: "GitHub",
      url: "https://github.com/Usmonkul",
    },
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/usmonkul-khakimov/",
    },
    {
      name: "Twitter",
      url: "https://x.com/UsmonHakim",
    },
    {
      name: "Instagram",
      url: "https://www.facebook.com/profile.php?id=61559885794014",
    },
    {
      name: "Hashnode",
      url: "https://hashnode.com/@usmonkul",
    },
  ],
  myProjects: [
    {
      title: "Marico",
      github: "https://github.com/usmonkul/marico",
      external: "https://marico-xi.vercel.app/",
      tech: [
        "Next js",
        "TypeScript",
        "Tailwind CSS",
        "Prisma",
        "SQLite",
      ],
      showInProjects: true,
      info: "The Marico website is a cutting-edge web application that helps to connect Creators with their audience.",
    },
    {
      title: "Movie App",
      github: "https://github.com/Usmonkul/nextjs-movie-app",
      external: "https://my-movie-app-beta.vercel.app/auth",
      tech: [
        "NextJS",
        "Tailwind CSS",
        "TypeScript",
        "NodeJS",
        "ExpressJS",
        "React",
        "Zustand",
        "Stripe",
        "Firebase",
        "Formik",
      ],
      showInProjects: true,
      info: "Full Stack Movie app. The users can buy a subscription, login and register on the app. Then, they will be able to watch the movies trailer and get information about them.",
    },
    {
      title: "Natours",
      github: "https://github.com/Usmonkul/Natours",
      external: "https://natours-huh.netlify.app",
      tech: ["HTML", "SCSS", "Fully-Responsive"],
      showInProjects: true,
      info: "Natours is fully responsive tour agency website built by only using HTML and SCSS. All the interaction and animations are the result of CSS code. It show the power of CSS.",
    },
    {
      title: "MediMatrix",
      github: "https://www.figma.com/file/9KdB23BBNdA8izTuWZRTPu/medimatrix?type=design&node-id=0%3A1&mode=design&t=Gds3ust7EHpGnVXw-1",
      external: "https://www.figma.com/file/9KdB23BBNdA8izTuWZRTPu/medimatrix?type=design&node-id=0%3A1&mode=design&t=Gds3ust7EHpGnVXw-1",
      tech: [
        "Figma",
        "Wireframe",
        "Design",
        "UI & UX"
      ],
      showInProjects: true,
      info: "The Medimatix is a medical platform website design. Designed by using Figma.",
    },
    {
      title: "Manage Landing page",
      github: "https://github.com/Usmonkul/manage-landing-page",
      external: "https://manage-l-pages.netlify.app/",
      tech: ["HTML", "Tailwind CSS", "JavaScript", "Swiper"],
      showInProjects: true,
      info: "Fully Responsive website with Graphs and Sliders",
    },
    {
      title: "Checkie inspection app",
      github: "https://github.com/Usmonkul/checkie-manager",
      external: "https://checkie-manager.vercel.app",
      tech: [
        "Next js",
        "React",
        "TypeScript",
        "Tailwind CSS",
        "Formik",
        "Figma",
      ],
      showInProjects: true,
      info: "Inspection manager app that Inspector able to create inspection target, inspection items, inspectors to monitor inspections on a target place",
    },
    {
      title: "myTeam",
      github: "https://github.com/Usmonkul/myteam",
      external: "https://myteam-huh.netlify.app",
      tech: ["HTML", "CSS", "JavaScript"],
      showInProjects: true,
      info: "Interactive and fully responsive multi-page website",
    },
    {
      title: "Simplify",
      github: "https://github.com/Usmonkul/simplify.io",
      external: "https://simlifyio.netlify.app",
      tech: ["HTML", "SCSS", "JavaScript"],
      showInProjects: true,
      info: "Simplify.io is Digital Learning platform website. it fully responsive and multi page website.",
    },
    {
      title: "Shortly",
      github: "https://github.com/Usmonkul/shortly",
      external: "https://shortly-huh.netlify.app",
      tech: ["HTML", "SCSS", "JavaScript"],
      showInProjects: true,
      info: "Shortly is website that makes links shorter, and meaningful. it's modern looking website, but only for desktop.",
    },
    {
      title: "Loop studio",
      github: "https://github.com/Usmonkul/loopstudio",
      external: "http://us-jamming.surge.sh/",
      tech: ["HTML", "SCSS", "JavaScript"],
      showInProjects: true,
      info: "Loop studio is landing page, and modern looking website to sell VR instruments.",
    },
    {
      title: "Easy Bank",
      github: "https://github.com/Usmonkul/easybank",
      external: "https://easybank-huh.netlify.app/",
      tech: ["HTML", "SCSS", "JavaScript"],
      showInProjects: true,
      info: "Easy bank is desktop and mobile responsive bank website. Special cards and unique colors are chosen to make the website outstanding. ",
    },
    {
      title: "Checkie",
      github: "https://www.figma.com/file/bWGmYhyQv5QNr8b46qdMws/CHEKIE?type=design&node-id=0%3A1&mode=design&t=48US4xbwIznI3az8-1",
      external: "https://www.figma.com/file/bWGmYhyQv5QNr8b46qdMws/CHEKIE?type=design&node-id=0%3A1&mode=design&t=48US4xbwIznI3az8-1",
      tech: [
        "Figma",
        "Wireframe",
        "Design",
        "UI & UX"
      ],
      showInProjects: true,
      info: "The Checkie is an ispection platform adminstarion website wireframe.",
    },
    {
      title: "Bookmark Landing page",
      github: "https://github.com/Usmonkul/bookmark-landing-page",
      external: "https://bookmark-huh.netlify.app",
      tech: ["HTML", "SCSS", "JavaScript"],
      showInProjects: true,
      info: "This is the website of browser extension. It use many JavaScript DOM features. It's only for desktop now.",
    },
  ],
  experience: {
    goqba: {
      title: "Software Engineer ",
      company: "GoQba Technology",
      date: "September 2023 - Present",
      description: [
        "Developed and managed front-end code for over four websites",
        "Conducting research and designing single-page websites and components",
        "Contributed to backend development for the administrative platform, incorporating CRUD operations through Flask and MySQL",
      ],
      stack: [
        "Python",
        "Flask",
        "Next.js",
        "React.js",
        "TypeScript",
        "JavaScript",
        "Tailwind CSS",
        "MySQL",
        "Figma",
        "Notion",
      ],
    },
    intern: {
      title: "Front-end Developer Internship ",
      company: "IDR Envision",
      date: "March 2023 - May 2023",
      description: [
        "Build a dynamic administration web app",
        "Gathered functional and non-functional requirements, and created high-level design diagrams",
        "Draw wireframe and design website UI using Figma",
      ],
      stack: [
        "React.js",
        "TypeScript",
        "JavaScript",
        "Figma",
        "Tailwind CSS",
      ],
    },
    freelance: {
      title: "Freelance Web Developer ",
      company: "Freelance",
      date: "January 2021 - February 2023",
      description: [
        "Collaborated with a team of developers or individually to build modern and responsive web applications using best practices.",
        "Build semantically structured web applications.",
        "Developed, maintained, and shipped production code for client websites.",
      ],
      stack: [
        "Next.js",
        "React.js",
        "TypeScript",
        "JavaScript",
        "Figma",
        "Formik",
        "Auth",
        "Firebase",
        "Firestore",
        "Node - Express",
        "Paymant - Stripe",
      ],
    },
    tutor: {
      title: "English Language Tutor ",
      company: "Dream Private Education Center",
      date: "July 2018 - August 2019",
      description: [
        "Teaching English as a Second Language to children aged between 9 to 15",
        "Get experience of working with childeren and leading them to achive signinficant skills in life.",
      ],
      stack: [
        "Enlish",
        "IELTS",
        "Teaching",
        "Leadership",
        "Presentation",
        "Easy-going",
      ],
    },
  },
};
